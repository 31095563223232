<template>
  <v-container fluid>
    <TableViewComponent
      title="Table grands domaines ROME"
      @addItemEvent="onAddElement()"
      addItemLabel="test"
      itemLabel="grand domaine ROME"
      itemsLabel="grands domaines ROME"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel=""
      :loading="loading"
      :showAddAction="false"
      :showDeleteAction="false"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete" 
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


import { RomeHightDomainsService} from "@/service/dictionary/rome_hight_domains_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableLargeDomainROME",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le classe d'accès au service */
      service: null,
      serviceMetier: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      // Pas d'ajout puisque liste issue de l'api pole emploi
      //this.$router.push(routes.largeDomainRome.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupère la liste en base
        let entities = await this.service.getAll();
        
        // Récupération de la liste des métiers
        let metiers = await this.serviceMetier.getAll();


        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          let metierName = '-';
          if (entity.metierId) {
            let metier = metiers.find((m) => m.id == entity.metierId);

            metierName = metier.name;
          }
          entity.metierName = metierName;


          entity.view = () => {
            this.$router.push(
              routes.largeDomainRome.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.largeDomainRome.edit.root + "/" + entity.id
            );
          };


          // Pas de suppression puisque liste issue de l'api pole emploi
          // entity.delete = async () => {
          //   try {
          //     await this.service.delete(entity.id);
          //     this.load();
          //   } catch (error) {
          //     console.error(error);
          //     this.addErrorToSnackbar(
          //       "suppression du point requis annonce : " +
          //         (exceptions.toMessage(error) || "problème technique")
          //     );
          //   }
          // };

          this.entities.push(entity);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Titre",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metierName",
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        //   align: "center",
        //   width: "80px",
        //   default: true,
        // },
      ];

      return columns;
    },
  },
    computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.ReadRomeHightDomain, ];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new RomeHightDomainsService(
      this.$api.getRomeHightDomainsApi()
    );
    this.serviceMetier = new MetierService(this.$api.getMetierApi());

    this.load();
  },
};
</script>

<style>
</style>